<template>
	<el-dialog title="审核" :visible.sync="isShow">
		<el-form  ref="form" :model="formData" label-width="120px">
			<el-input type="hidden" v-model="formData.id"></el-input>
			<el-row>
				<el-form-item label="审核结果">
					<el-radio-group v-model="formData.status" @change="$forceUpdate()">
						<el-radio :label="1">通过</el-radio>
						<el-radio :label="4">不通过</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-row>
			<el-row>
				<el-form-item label="">
					<el-input type="textarea" :rows="8" v-model="formData.failMsg" placeholder="不通过必须填写理由"
						style="padding:0 50px;"></el-input>
				</el-form-item>
			</el-row>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="close()">取消</el-button>
			<el-button type="primary" @click="examine()">确定</el-button>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				formData: {
					status: 1
				}
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = this.dialogObj;
				this.formData["status"] = 1;
			},
			//审核
			examine() {
				if (this.dialogObj.id) {
					this.$post("/user-api/exp/user/audit/save", this.formData).then((res) => {
						if (res.code == 1000) {
							this.close();
							//刷新表格
							this.$parent.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				}
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss'>
</style>
