<template>
	<div class="flex" style="flex-direction: column;height: 100%;">
		<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height:80px;">
			<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
				<el-row class="flex-1">
					<el-col :span="6">
						<el-form-item label="用户账号：">
							<el-input v-model="searchParm.accountNo" placeholder="用户账号/手机/姓名"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="审核状态：">
							<el-select v-model="searchParm.status" :popper-append-to-body="false" placeholder="请选择">
								<el-option label="全部" value="0"></el-option>
								<el-option label="审核通过" value="1"></el-option>
								<el-option label="注册未完善信息" value="2"></el-option>
								<el-option label="信息已完善未审核" value="3"></el-option>
								<el-option label="审核不通过" value="4"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="10">
						<el-row>
							<el-form-item label="注册时间：">
								<el-col :span="10">
									<el-date-picker v-model="searchParm.regTimeStart" value-format="yyyy-MM-dd HH:mm:ss"
										type="datetime" placeholder="请选择日期">
									</el-date-picker>
								</el-col>
								<el-col :span="2" style="text-align: center;">
									<span>-</span>
								</el-col>
								<el-col :span="10">
									<el-date-picker v-model="searchParm.regTimeEnd" value-format="yyyy-MM-dd HH:mm:ss"
										type="datetime" placeholder="请选择日期">
									</el-date-picker>
								</el-col>
							</el-form-item>
						</el-row>
					</el-col>
				</el-row>
				<div>
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card>
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
				<div class="title-right">
					<el-select v-model="searchParm.size" size="medium" :popper-append-to-body="false" placeholder="显示条数"
						@change="list()">
						<el-option label="5" :value="5"></el-option>
						<el-option label="10" :value="10"></el-option>
						<el-option label="20" :value="20"></el-option>
						<el-option label="50" :value="50"></el-option>
						<el-option label="100" :value="100"></el-option>
					</el-select>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table ref="usertable" :data="tableData.records" border :height="tableHeight"
							:header-cell-style="{'background-color':'#f9fafc'}" :cell-style="{'padding':0}"
							style="margin-bottom: 20px;">
							<el-table-column type="selection" align="center" width="55">
							</el-table-column>
							<el-table-column prop="accountNo" align="center" label="用户账号" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="mobile" align="center" label="手机" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="realName" align="center" label="姓名" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="regTime" align="center" label="注册时间" show-overflow-tooltip
								:formatter="(row, column)=>{ 
								let data = row.regTime;
									if (data ==='') {
											 return ''
										 }else {
											 let dt = new Date(data)
											 return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds()
										}
									}
							">
							</el-table-column>
							<el-table-column prop="status" align="center" label="审核状态" show-overflow-tooltip>
								<template slot-scope="scope">
									<span v-if="scope.row.status==1">审核通过</span>
									<span v-else-if="scope.row.status==2">注册未完善信息</span>
									<span v-else-if="scope.row.status==3">信息已完善未审核</span>
									<span v-else-if="scope.row.status==4">审核不通过</span>
								</template>
							</el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="120">
								<template slot-scope="scope">
									<el-button type="text" @click="look(scope.row)">
										查看
									</el-button>
									<el-button type="text" @click="examine(scope.row)" v-if="scope.row.status==3">
										审核
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination style="float: right;" @current-change="handleCurrentChange"
						:current-page.sync="searchParm.current" :page-size="searchParm.size"
						layout="total, prev, pager, next,jumper" :total="tableData.total">
					</el-pagination>
				</div>
			</div>
		</div>

		<look :isShow.sync="showLookDialog" :dialogObj="lookObj"></look>
		<examine :isShow.sync="showExamineDialog" :dialogObj="examineObj"></examine>
	</div>
</template>
<script>
	import smCard from "@c/smCard.vue"
	import look from "./look.vue"
	import examine from "./examine.vue"
	export default {
		components: {
			smCard,
			look,
			examine
		},
		data() {
			return {
				tableHeight: 0,
				tableData: {
					records: [],
				},
				searchParm: {
					size: 10,
					current: 1
				},
				// batchActionType: '',
				showLookDialog: false,
				showExamineDialog: false,
				lookObj: {},
				examineObj: {},
			}
		},
		methods: {
			//自适应表格高度
			calHeight() {
				this.$nextTick(() => {
					const rect = this.$refs.tableContainer.getBoundingClientRect()
					this.tableHeight = rect.height
				})
			},
			//清空搜索
			clearSearch() {
				this.searchParm = {
					size: 10,
					current: 1
				}
			},
			//展示数据
			list() {
				this.$get("/user-api/exp/user/audit/list", this.searchParm).then((res) => {
					if (res.code == 1000) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//打开查看弹窗
			look(row) {
				this.lookObj = {};
				this.lookObj["id"] = row.id;
				this.showLookDialog = true;
			},
			//打开审核弹窗
			examine(row) {
				this.examineObj = {};
				this.examineObj["id"] = row.id;
				this.showExamineDialog = true;
			},
			//分页选择页面回调
			handleCurrentChange(val) {
				this.searchParm.current = val;
				this.list();
			},
		},
		mounted() {
			this.list();
			this.calHeight();
			window.addEventListener('resize', this.calHeight)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.calHeight)
		},
	};
</script>
<style lang='scss' scoped>
	@import "@style/userCenter.scss"
</style>
